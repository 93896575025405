import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/chip/code/gpt2/static-site/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The blog content of this website is written by an AI. The recipes and instructions are from real recipes. Feel free to make them!  `}</p>
    <p>{`The website itself is built by `}<a href="https://twitter.com/themacrochip" target="_blank" rel="noopener">{`Chip Thien`}</a>{`  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      